import React from 'react';
import ReactDOM from 'react-dom';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Player from "./Player";
import {AppConsumer} from "../AppContext";
import { Link } from 'react-router-dom'

const SharedAudioPlayer = () => (
    <AppConsumer>
        {({ snackbarIsOpen, message, songs, cover, closeSnackbar, trackId, albumRoute }) => (
            //<div>
            //    <img key="albumCover" src={cover} alt={message} title={message}/>
            //    <Player key="audioPlayer" title={message} songs={songs} cover={cover} />
            //</div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarIsOpen}
                //autoHideDuration={6000}
                //onClose={closeSnackbar}
                //message={message}
                action={[
                    <Link to={"/album/" + albumRoute} key={"albumRoute"}>
                        <img key="albumCover" src={cover} alt={message} title={message}/>
                    </Link>,
                    <Player key="audioPlayer" title={message} songs={songs} cover={cover} trackId={trackId} />,
                    <IconButton key="close" color="inherit" onClick={closeSnackbar}>
                        <Close key={"closePlayer"} />
                    </IconButton>,
                ]}
            />
        )}
    </AppConsumer>
);
export default SharedAudioPlayer;