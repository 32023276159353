import React, {Component} from "react";
import MetaData from "../components/MetaData";
import {Link} from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";


class StartPage extends Component {
    render() {

        const slideImages = [
            {url: 'bittersweet_xl.jpg', link: 'http://efdfef'},
            {url: 'retrospections_xl.jpg'},
            {url: 'more_than_memories_xl.jpg'},
            {url: 'a_journey_xl.jpg'},
        ];

        return (
            <div className="containerHome">
                <MetaData {...{ title: 'Welcome To My World!', keywords: global.stefano,
                    imgUrl: ''}}/>
                <SimpleImageSlider
                    width={800}
                    height={600}
                    images={slideImages}
                    navStyle={2}
                    showBullets={1}
                    slideDuration={0.5}
                />
            </div>
        )
    }
}
export default StartPage