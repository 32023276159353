import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import MetaData from "../components/MetaData";

class HomePage extends Component {
    render() {
        const headText = "Welcome to the official website of Stefano Giardiniere!";
        const AutoplaySlider = withAutoplay(AwesomeSlider);

        // NTH: Install Button...
        /*
        <div>
            <button className="btnAddApp">Add app to home screen</button>
        </div>
         */

        var toDate = new Date();
        var today = parseInt(toDate.getDate());
        var month = parseInt(toDate.getMonth()) + 1;

        /*if(month == 12 || (month == 11 && today > 27)) {
            return (
                <div className="container" id={'showContentFaster'}>
                    <MetaData {...{ title: headText, keywords: global.stefano,
                        imgUrl: ''}}/>
                    <div className="topTitle">{headText}</div>
                    <AutoplaySlider className="sliderHome" play={true} interval={10000}>
                        <div data-src="sg_slider_xmas.jpg">
                            <a href="/xmas"> </a>
                        </div>
                        <div data-src="sg_slider_cascata.jpg">
                            <a href="https://youtu.be/GLcIRcGWLZw" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_crepuscolo.jpg">
                            <a href="https://youtu.be/m2LKSoFSKPc" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_premuroso.jpg">
                            <Link to="/album/premuroso"> </Link>
                        </div>
                        <div data-src="sg_slider_pensoso.jpg">
                            <a href="https://youtu.be/GpjCd_z6IWU" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_bittersweet.jpg" id={"slideBittersweet"}>
                            <Link to="/album/bittersweet"> </Link>
                        </div>
                        <div data-src="sg_slider_retrospections.jpg">
                            <Link to="/album/retrospections"> </Link>
                        </div>
                        <div data-src="sg_slider_more_than_memories.jpg">
                            <Link to="/album/more_than_memories"> </Link>
                        </div>
                        <div data-src="sg_slider_a_journey.jpg">
                            <Link to="/album/a_journey"> </Link>
                        </div>
                    </AutoplaySlider>
                </div>
            )

                        <div data-src="sg_slider_moods.jpg" alt={"Stefano's Advent Calendar"} title={"Stefano's Advent Calendar"}>
                            <Link to="/xmas"> </Link>
                        </div>


        } else {*/
            return (
                <div className="container" id={'showContentFaster'}>
                    <MetaData {...{ title: headText, keywords: global.stefano,
                        imgUrl: ''}}/>
                    <div className="topTitle">
                        {headText}
                    </div>
                    <AutoplaySlider className="sliderHome" play={true} interval={10000}>
                        <div data-src="sg_slider_moods.jpg" alt={"Moods from Different Angles"} title={"Moods from Different Angles"}>
                            <a href="https://stefano-giardiniere.bandcamp.com/album/moods-from-different-angles" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_wings_of_sadness.jpg" alt={"Stefano Giardiniere - On Wings Of Sadness"} title={"Stefano Giardiniere - On Wings Of Sadness"}>
                            <a href="https://youtu.be/AohItu_Iqmc" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_running_free.jpg" alt={"Stefano Giardiniere - Running Free"} title={"Stefano Giardiniere - Running Free"}>
                            <a href="https://youtu.be/L_JPyQbjqRM" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_cascata.jpg" alt={"Stefano Giardiniere - Fino alla Cascata"} title={"Stefano Giardiniere - Fino alla Cascata"}>
                            <a href="https://youtu.be/GLcIRcGWLZw" target="_blank"> </a>
                        </div>
                        <div data-src="sg_slider_premuroso.jpg" alt={"Stefano Giardiniere - Premuroso"} title={"Stefano Giardiniere - Premuroso"}>
                            <Link to="/album/premuroso"> </Link>
                        </div>
                        <div data-src="sg_slider_bittersweet.jpg" id={"slideBittersweet"} alt={"Stefano Giardiniere - Bittersweet"} title={"Stefano Giardiniere - Bittersweet"}>
                            <Link to="/album/bittersweet"> </Link>
                        </div>
                        <div data-src="sg_slider_retrospections.jpg" alt={"Stefano Giardiniere - Retrospections"} title={"Stefano Giardiniere - Retrospections"}>
                            <Link to="/album/retrospections"> </Link>
                        </div>
                        <div data-src="sg_slider_a_journey.jpg" alt={"Stefano Giardiniere - A Journey"} title={"Stefano Giardiniere - A Journey"}>
                            <Link to="/album/a_journey"> </Link>
                        </div>
                    </AutoplaySlider>
                </div>
            )
        // }
	}
}
export default HomePage