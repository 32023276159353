import React, {Component} from "react";
import SharedAudioPlayer from "./components/AudioPlayer";

const AppContext = React.createContext({});

export class AppProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            isOpen: false,
            message: '',
            songs: [],
            cover: '',
            trackId: '',
            albumRoute: ''
        };
    }

    openSnackbar = (message, songs, cover, trackId, albumRoute) => {
        if(this.state.isOpen) {
            if(this.closeSnackbar()) {
                this.setState({
                    message,
                    songs,
                    cover,
                    trackId,
                    albumRoute,
                    isOpen: true,
                    isPlaying: true,
                });
            }
        } else {
            this.setState({
                message,
                songs,
                cover,
                trackId,
                albumRoute,
                isOpen: true,
                isPlaying: true,
            });
        }
    };

    closeSnackbar = () => {
        this.setState({
            message: '',
            isOpen: false,
            songs: [],
            cover: '',
            trackId: '',
            albumRoute: ''
        });
        return true;
    };

    render() {
        const { children } = this.props;

        return (
            <AppContext.Provider
                value={{
                    openSnackbar: this.openSnackbar,
                    closeSnackbar: this.closeSnackbar,
                    snackbarIsOpen: this.state.isOpen,
                    message: this.state.message,
                    songs: this.state.songs,
                    cover: this.state.cover,
                    trackId: this.state.trackId,
                    albumRoute: this.state.albumRoute
                }}
            >
                <SharedAudioPlayer/>
                {children}
            </AppContext.Provider>
        );
    }
}
export const AppConsumer = AppContext.Consumer;