import React, { Component } from 'react';

class Cover extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.pathXL = null;
    }

    handleClick() {
        if(this.pathXL !== null && this.pathXL !== '') {
            // Show the XL Cover
            if(document.getElementById('xlCover').className === 'xlCoverHidden') {
                document.getElementById('xlCover').setAttribute("class", "xlCoverVisible");
            } else {
                document.getElementById('xlCover').setAttribute("class", "xlCoverHidden");
            }
            if(document.getElementById('xlCoverImg').className === 'xlCoverHiddenImage') {
                document.getElementById('xlCoverImg').setAttribute("class", "xlCoverVisibleImage");
            } else {
                document.getElementById('xlCoverImg').setAttribute("class", "xlCoverHiddenImage");
            }
            if(document.getElementById('xlCoverTitle').className === 'xlCoverHiddenTitle') {
                document.getElementById('xlCoverTitle').setAttribute("class", "xlCoverVisibleTitle");
            } else {
                document.getElementById('xlCoverTitle').setAttribute("class", "xlCoverHiddenTitle");
            }
            if(document.getElementById('xlCoverArtist').className === 'xlCoverHiddenArtist') {
                document.getElementById('xlCoverArtist').setAttribute("class", "xlCoverVisibleArtist");
            } else {
                document.getElementById('xlCoverArtist').setAttribute("class", "xlCoverHiddenArtist");
            }
        }
    }

    render() {
        var title   = global.stefano;
        var name = '';
        var path    = global.sg_url + 'cover/albums/98.jpg'; // NTH: Default cover

        if(this.props.title !== undefined && this.props.title !== null && this.props.title !== '') {
            title += ' - ' + this.props.title;
            name = this.props.title;
        }
        if(this.props.type !== undefined && this.props.type !== null && this.props.file !== undefined && this.props.file !== null) {
            path = global.sg_url + 'cover/' + this.props.type + '/' + this.props.file;
            this.pathXL = global.sg_url + 'cover/' + this.props.type + '/large/' + this.props.file;
            // NTH: Check if exists etc.
            //{'showCoverLarge(' + pathXL + ')'}
        }
        return (
            <div className="albumCover">
                <div className="coverHover"><img id="cover" src={path} title={title} alt={title} onClick={this.handleClick} width={300} height={300} /></div>
                <div id="xlCover" className="xlCoverHidden" onClick={this.handleClick}>
                    <div className="closeFrame">X</div>
                    <span id="xlCoverArtist" className="xlCoverHiddenArtist">{global.stefano}</span>
                    <br/>
                    <img id="xlCoverImg" className="xlCoverHiddenImage" src={this.pathXL} title={title} alt={title} />
                    <br/>
                    <span id="xlCoverTitle" className="xlCoverHiddenTitle">{name}</span>
                </div>
            </div>
        )
    }
}
export default Cover