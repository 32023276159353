import React, { Component } from 'react'

class ContentComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(this.props.contents === null || this.props.contents === undefined || this.props.contents === '') {
            return (<div>&nbsp;</div>)
        } else {
            const output = this.props.contents.map(function(content){
                var aTarget = '_self';
                if(parseInt(content.link_blank) === 1) {
                    aTarget = '_blank';
                }

                switch (content.content_type) {
                    case 'embed': // TODO: Cookie Prüfen!
                        var embedImage = '';
                        var embedFrame = '';
                        if(document.cookie.indexOf("SGcooacc=") >= 0) {
                            embedImage = (
                                <img src={content.value2} title={content.value1} alt={content.value1}
                                     onClick={() => { // TODO: Player-Status prüfen und ggf. Stoppen!
                                         var embedSettings = '?autoplay=1&rel=0';
                                         if(content.value1.indexOf('Playlist') !== -1) {
                                             embedSettings = '&autoplay=1&rel=0';
                                         }

                                         if(content.value3 !== '') {
                                             document.getElementById('frameContainer' + content.id).setAttribute("class", "visibleFrameContainer");
                                             document.getElementById('videoFrame' + content.id).setAttribute("class", "visibleFrame");
                                             document.getElementById('videoFrame' + content.id).setAttribute("src", content.value3 + embedSettings);
                                         }
                                     }} />
                            );
                            embedFrame = (<iframe title={content.value1} id={'videoFrame' + content.id} className="hiddenFrame" frameBorder="0" allowFullScreen />);
                        } else {
                            embedImage = (<img src={content.value2} className="noPermission" title="Please activate cookies to watch the video!" alt={content.value1}/>);
                        }

                        return (
                            <div className="videoItem" key={content.id}>
                                {embedImage}
                                <div className="hiddenFrameContainer" id={'frameContainer' + content.id}>
                                    <span className="frameTitle">{global.stefano}</span>
                                    <div className="closeFrame" onClick={() => {
                                        document.getElementById('videoFrame' + content.id).setAttribute("class", "hiddenFrame");
                                        document.getElementById('frameContainer' + content.id).setAttribute("class", "hiddenFrameContainer");
                                        document.getElementById('videoFrame' + content.id).setAttribute("src", '');
                                    }}>X</div>
                                    {embedFrame}
                                    <span className="frameSong">{content.value1}</span>
                                </div>
                            </div>
                        );
                    case 'image':
                        var imgTitle = '';
                        if(content.value2 !== '') {
                            imgTitle = (<div className={content.value2class} title={content.value2}>{content.value2}</div>);
                        }
                        //console.log(content);
                        var imgWidth = 0;
                        var imgHeight = 0;
                        if(content.value1class === "bioImg") {
                            imgWidth = 300;
                            imgHeight = 227;
                        }
                        return (
                            <div key={content.id}>
                                <div className={content.value1class}>{content.value1}</div>
                                <img src={content.value3} title={content.value2} width={imgWidth} height={imgHeight} draggable={false} alt={content.value2} className={content.value3class} />
                                {imgTitle}
                            </div>
                        );
                    case 'info': // TODO
                        // TODO: ein info-Content kann x subcontents haben
                        // if content.subcontents...map
                        return (
                            <div key={content.id}>
                                <div className={content.value1class}>{content.value1}</div>
                            </div>
                        );
                    case 'link':
                        if (parseInt(content.is_embed) === 1 && document.cookie.indexOf("SGcooacc=") >= 0) {
                            return (
                                <div key={content.id}>
                                    <div className={content.value1class}>{content.value1}</div>
                                    <iframe className={content.value3class} src={content.value3} title={content.value2} />
                                </div>
                            )
                        } else {
                            var linkUrl = '';
                            if(content.value1class === '') {
                                linkUrl = (
                                    <a href={content.value3} target={aTarget} className={content.value3class} title={content.value1}>{content.value1}</a>
                                );
                            } else {
                                linkUrl = (
                                    <a href={content.value3} target={aTarget} className={content.value3class} title={content.value1}>
                                        <div className={content.value1class}>{content.value1}</div>
                                    </a>
                                );
                            }
                            return (
                                <div key={content.id}>
                                    {linkUrl}
                                    <div className={content.value2class}>{content.value2}</div>
                                </div>
                            );
                        }
                    default:
                        return (<div key={content.id}>&nbsp;</div>)
                }
            });
            // const moon = document.getElementById('moon');
            // if(!moon.hasAttribute('hidden')) {
            //     moon.setAttribute('hidden', 'true');
            // }
            return(<div>{output}</div>)
        }
    }
}
export default ContentComponent