import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CheeseburgerMenu from 'cheeseburger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import MenuContent from './BurgerMenu';

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			menuOpen: false,
		}
	}
	openMenu() {
		this.setState({ menuOpen: true })
	}
	closeMenu() {
		this.setState({ menuOpen: false })
	}
	resetPage(event) {
		//this.setState({ games: [] });
	}

	render() {
		return (
			<div>
		        <header>
					<Link to="" title={"Stefano-Giardiniere.com"}>
						<div onClick={ this.resetPage.bind(this) } className="logoLarge" title={"Stefano Giardiniere"}></div>
						<div className="logoSmall" title={"Stefano Giardiniere"}></div>
					</Link>
			        <ul id="headerButtons">
						<li className="navButton" title={"About"}><Link to="/about">About</Link></li>
						<li className="navButton" title={"Records"}><Link to="/records">Records</Link></li>
                        <li className="navButton" title={"Impressions"}><Link to="/impressions">Impressions</Link></li>
						<li className="navButton" title={"Videos"}><Link to="/videos">Videos</Link></li>
			        </ul>
					<div className="burgerMenu">
						<CheeseburgerMenu
							isOpen={this.state.menuOpen}
							closeCallback={this.closeMenu.bind(this)}>
							<MenuContent closeCallback={this.closeMenu.bind(this)}/>
						</CheeseburgerMenu>

						<HamburgerMenu
							isOpen={this.state.menuOpen}
							menuClicked={this.openMenu.bind(this)}
							width={20}
							height={20}
							strokeWidth={2}
							rotate={0}
							color='#808080'
							borderRadius={0}
							animationDuration={0.5}
						/>
					</div>
		      	</header>
			</div>
		)
	}
}
export default Header