import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

class MetaData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var pageTitle = global.stefano;
        if(this.props.title !== undefined && this.props.title !== null && this.props.title !== '') {
            pageTitle += ' - ' + this.props.title;
        }
        return (
            <MetaTags>
                <title>{pageTitle}</title>
                {this.props.keywords !== undefined && this.props.keywords !== null && this.props.keywords !== '' &&
                <meta name="keywords" content={this.props.keywords} />
                }
                {this.props.description !== undefined && this.props.description !== null && this.props.description !== '' &&
                <meta name="description" content={this.props.description } />
                }
                <meta property="og:title" content={pageTitle} />
                {this.props.imgUrl !== undefined && this.props.imgUrl !== null && this.props.imgUrl !== '' &&
                <meta property="og:image" content={this.props.imgUrl}/>
                }
            </MetaTags>
        )
    }
}
export default MetaData