import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import Cover from "../components/Cover";
import MetaData from "../components/MetaData";
import nl2br from 'react-nl2br'
import ReactDOM from 'react-dom';
import PlayButton from "../components/PlayButton";
import ContentComponent from "../components/ContentComponent";
//import {Scrollbars} from "react-custom-scrollbars";

class AlbumPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            error : null,
            isLoading : true,
            album : []
        };
        this.songs = null;
    }

    componentDidMount () {
        if (this.props.match.params.id !== undefined && this.props.match.params.id !== null && this.props.match.params.id !== '') {
            var body = { "route": this.props.match.params.id };
            axios.post( global.sg_url + 'api/get_album', body  )
                .then(response => {
                    this.setState({ isLoading: false, album: response.data });
                })
                .catch(error => {
                    var errTxt = "Unknown error.";
                    switch (parseInt(error.response.status, 10)) {
                        case 401:
                            errTxt = "Not authenticated!";
                            break;
                        case 403:
                            errTxt = "Forbidden action!";
                            break;
                        case 404:
                            errTxt = "No data found!";
                            break;
                        default:
                            break;
                    }
                    this.setState({ isLoading: false, error: errTxt });
                });
        }
    }

    /*
    <Scrollbars
        autoHide
        autoHeight
        autoHeightMin={877}
        // autoHeightMax={690}
        // universal={true}
    >
    */

    render() {
        document.body.style.backgroundColor = '#375a95';

        if(this.state.isLoading) {
            return <div className="container">&nbsp;</div>
        } else {
            if (this.state.error && this.state.album[0] === undefined) {
                return<div className="container">
                        <h1>Error!</h1>
                        {this.state.error}
                    </div>
            } else {
                var album = this.state.album[0];
                this.songs = this.state.album['songs'];
                var contents = this.state.album['contents'];
                var pageTitle = album.album;
                if(pageTitle !== album.page_title) {
                    pageTitle = album.page_title;
                }
                var metaDesc = album.meta_description;
                if(parseInt(album.jamendo_id) > 0) {
                    metaDesc += ' Listen to the full album here.';
                }
                var albumRoute = album.route_name;

                return <div className="containerAlbum" id={'showContentFaster'}>
                                <MetaData {...{ title: album.album + ' (' + album.year + ')', keywords: album.keywords, description: metaDesc,
                                                imgUrl: global.sg_url + 'cover/albums/' + album.cover}}/>
                                <div className="albumMain">
                                    <Cover {...{type: 'albums', title: album.album + ' (' + album.year + ')', file: album.cover}}/>
                                    <div className="albumTitleSongs">
                                        <h1>{pageTitle}</h1>
                                        {album.headline !== '' &&
                                        <div>{album.headline}</div>
                                        }

                                        <ol>
                                            {
                                                this.songs.map(song => (
                                                    <li key={song.id}>
                                                        <div className="tit">{song.title}</div>
                                                        <div className="len">
                                                            {song.length}
                                                            {/*[song]*/}
                                                            {parseInt(album.jamendo_id) > 0 &&
                                                            <PlayButton playtype="►" title={song.title} songs={this.songs} cover={global.sg_url + 'cover/albums/small/' + album.cover} albumRoute={albumRoute} trackId={song.id} />
                                                            }
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                </div>
                                <div className="albumContent">
                                    {parseInt(album.jamendo_id) > 0  &&
                                        <div className="playButtonLarge">
                                            <PlayButton playtype="► Play Full Album" title={album.album} songs={this.songs} cover={global.sg_url + 'cover/albums/small/' + album.cover} trackId="" albumRoute={albumRoute} />
                                        </div>
                                    }

                                    {album.length !== '' &&
                                        <div>Length: {album.length}</div>
                                    }
                                    {album.year !== '' &&
                                        <div>Year: {album.year}</div>
                                    }
                                    {album.gms_code !== '' &&
                                        <div>GMS-Records ID: {album.gms_code}</div>
                                    }
                                    {/*// NTH: Texte auch in "CONTENTS"*/}
                                    {album.info !== '' &&
                                    <div className="additionalInfo">{nl2br(album.info)}</div>
                                    }
                                    {album.additional_1 !== '' &&
                                    <div className="additionalInfo">{nl2br(album.additional_1)}</div>
                                    }
                                    {contents !== null && contents !== undefined && contents !== '' &&
                                        <ContentComponent contents={contents} />
                                    }
                                </div>
                        </div>

            }
        }
    }
}
export default AlbumPage