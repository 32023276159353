import { Button } from '@material-ui/core';
import React from 'react';
import {AppConsumer} from "./../AppContext";

/*
// <div onClick={() => openSnackbar(props.title, props.songs, props.cover)}>
//     {props.playtype}
// </div>
 */
// NTH: Playtype = int > wenn 1, dann album, wenn 2, dann song
const PlayButton = (props) => (
    <AppConsumer>
        {({ openSnackbar }) => (
            <Button
                title={props.title}
                className="playButton"
                variant="contained"
                color="primary"
                onClick={() => openSnackbar(props.title, props.songs, props.cover, props.trackId, props.albumRoute)}>
                {props.playtype}
            </Button>
        )}
    </AppConsumer>
);
export default PlayButton;