import React, {PureComponent} from 'react'
import { Map, TileLayer, Marker, Popup, ScaleControl  } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import Leaflet from 'leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


class MapComponent extends PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        var centerPosition = [20.055, 10.70]; // = > DD [51.055, 13.70]; // className="map__reactleaflet"
        var Markers;

        if( this.props.contents !== null && this.props.contents !== undefined &&  this.props.contents !== '' &&
            this.props.contents[0] !== null && this.props.contents[0] !== undefined) {
            const lastEntry = this.props.contents[0];
            //centerPosition = [lastEntry.latitude, lastEntry.longitude]; // Durchschnitt berechnen
            const mapPositions = this.props.contents;
            Markers = mapPositions.map(marker => (
                <div key={marker.id}>
                    <Marker position={[marker.latitude,  marker.longitude]}>
                        <Popup>
                            {/*NTH: Hier Content einbinden*/}
                            <a>{marker.value1}</a><br />
                            <img src={'img/thumb/' + marker.value3} border="0" title={marker.value1} alt={marker.value1} onClick={() => {
                                document.getElementById('mapContentContainer' + marker.id).setAttribute("class", "mapContentContainerVisible");
                                {/*NTH: Wenn offen, dann kein Bewegen der Karte! / ev. cursor... */}
                            }} />
                            <br />
                            {marker.value2}
                        </Popup>
                    </Marker>
                    <div className="mapContentContainerHidden" id={'mapContentContainer' + marker.id} >
                        <div className="closeContentFrame" id={'closeContainer' + marker.id} onClick={() => {
                            document.getElementById('mapContentContainer' + marker.id).setAttribute("class", "mapContentContainerHidden");
                        }}>X</div>
                        <span className="positionTitle">{marker.value1}</span>
                        <img src={'img/' + marker.value3} border="0" title={marker.value1} alt={marker.value1} />
                        <span className="positionText">{marker.value2}</span>
                    </div>
                </div>
            ))
        } else {
            Markers = (<Marker position={centerPosition} />);
        }
        return (
            <div className="map" id={'showContent'}>
                <Map center={centerPosition} zoom={3}>
                    <ScaleControl position="bottomright" />
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    {Markers}
                </Map>
            </div>
        );
    }
}
export default MapComponent;