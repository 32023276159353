import React, { PureComponent, useState, useEffect } from 'react'
import axios from 'axios'
import RecordsPage from "./RecordsPage";
import MetaData from "../components/MetaData";
import ContentComponent from "../components/ContentComponent";
import MapComponent from "../components/MapComponent";
import ContactForm from '../components/ContactForm'
import PrivacyPage from "./PrivacyPage";
import {Scrollbars} from "react-custom-scrollbars";

class LoadPage extends PureComponent {
	constructor (props) {
	    super(props);
        this.state = {
            error : null,
            pageData : []
        };
	}
	componentDidMount () {
        this.getPageData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.path !== this.props.match.path) {
            this.getPageData();
        }
    }

    getPageData = () => {
        if (this.props.match.path !== undefined && this.props.match.path !== null && this.props.match.path !== '') {
            var pageId = this.props.match.path;
            pageId = pageId.slice(1);

            if(pageId === 'privacy') {
                return;
            }

            var withContents = 1;
            var getMapContents = 0;
            var orderDesc = '';

            if(pageId === 'records') {
                withContents = 0;
            }
            if(pageId === 'impressions') { //  || pageId === 'links'
                getMapContents = 1;
            }
            //if(pageId === 'videos' || pageId === 'links' || pageId === 'contact') {
                 this.setState({pageData: []});
            //}
            if(pageId === 'videos') {
                orderDesc = 'Krub';
            }
            var body = {"page": pageId, "contents": withContents, "map": getMapContents, "orderDesc": orderDesc};

            axios.post(global.sg_url + 'api/get_page', body)
                .then(response => {
                    this.setState({pageData: response.data});
                })
                .catch(error => {
                    var errTxt = "Unknown error";
                    switch (parseInt(error.response.status, 10)) {
                        case 401:
                            errTxt = "Not authenticated!";
                            break;
                        case 403:
                            errTxt = "Forbidden action!";
                            break;
                        case 404:
                            errTxt = "No data found!";
                            break;
                        default:
                            break;
                    }
                    this.setState({error: errTxt});
                });
        }
    };

    render() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        document.body.style.backgroundColor = '#375a95';

		const pageId = this.props.match.path;
        var pageTitle = '';
        var shownTitle = '';
        var pageKeywords = global.keywords;
        var pageDescription = global.metaDesc;
        var contents = [];

        if(this.state.pageData[0] !== null && this.state.pageData[0] !== undefined && this.state.pageData[0] !== '') {
            var pageData = this.state.pageData[0];
            pageTitle = pageData.title;
            pageKeywords = pageData.keywords;
            pageDescription = pageData.meta_description;
            shownTitle = pageTitle + '...';

            if(this.state.pageData.contents !== null && this.state.pageData.contents !== undefined) {
                contents = this.state.pageData.contents;
            }
        }

	    switch(pageId.slice(1)) {
	    	case "about":   // title: 'On My Way...'
	    		return (
					<div className="container">
                        <MetaData {...{ title: pageTitle, keywords: pageKeywords, description: pageDescription,
                                        imgUrl: global.imgUrl}}/>
                        <div className="topTitle">About Stefano Giardiniere</div>
                        <div className="bioGrid" id={'showContent'}>
                            <ContentComponent contents={contents} />
                            <div>
                                Stefano Giardiniere was supporting Music-For-Nature with his songs "
                                <a href={"https://youtu.be/2EAV9cSnLW8"} className={"stdTxt"} target={"_blank"} title={"Bittersweet Waterfalls"}>Bittersweet Waterfalls</a>" and "
                                <a href={"https://youtu.be/kirMfT5Wnyw"} className={"stdTxt"} target={"_blank"} title={"When I Got Lost"}>When I Got Lost</a>".
                            </div>
                        </div>
				    </div>
				);
            case "contact":
                return(
                        <div className="container">
                            <MetaData {...{ title: pageTitle, keywords: '', description: '', imgUrl: global.imgUrl}}/>
                            <div className="topTitle">Contact me...<br/>
                                <span>
                                    mail@stefano-giardiniere.com
                                </span>
                            </div>
                            <ContactForm  />
                        </div>
                );
            case "impressions":
                return (
                    <div className="container">
                        <MetaData {...{ title: pageTitle, keywords: pageKeywords, description: pageDescription,
                            imgUrl: global.imgUrl}}/>
                        <div className="topTitle">Impressions from my travels...</div>
                        <MapComponent contents={contents} />
                    </div>
                );
            case "links":
                return (
                    <div className="container">
                        <MetaData {...{ title: pageTitle, keywords: pageKeywords, description: pageDescription,
                            imgUrl: global.imgUrl}}/>
                        <div className="topTitleStd">Find out more about me or give me your support on the following websites...</div>
                        <div className="linksGrid" id={'showContentFaster'}>
                            <ContentComponent contents={contents} />
                        </div>
                    </div>
                );
            case "privacy":
                return(
                    <div className="container">
                        <MetaData {...{ title: 'Privacy Policy', keywords: '', description: '', imgUrl: global.imgUrl}}/>
                        <div className="topTitle">Data Privacy Policy</div>
                        <PrivacyPage/>
                    </div>
                );
	    	case "records":
				return (
				    <div className="container">
                        <MetaData {...{ title: pageTitle, keywords: pageKeywords, description: pageDescription,
                                        imgUrl: global.imgUrl}}/>
                        <div className="topTitle">{shownTitle}</div>
                        <RecordsPage />
				    </div>);
            case "videos":
                return (
                    <div className="container">
                        <MetaData {...{ title: pageTitle, keywords: pageKeywords, description: pageDescription,
                                        imgUrl: global.imgUrl}}/>
                        <div className="topTitle">{shownTitle}</div>
                        <div className="videoGrid" id={'showContentSlow'}>
                            <ContentComponent contents={contents} />
                        </div>
                    </div>);
	    	default: // Page not found
				return (
                    <div className="container">
                        <MetaData {...{ title: 'Page not found...', keywords: pageKeywords, description: pageDescription,
                                        imgUrl: global.imgUrl}}/>
                        <div className="topTitle">{pageId.slice(1)}</div>
                        {this.state.error}
                    </div>);
	    }
	}
}
export default LoadPage;