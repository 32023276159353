import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

class RecordsPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            error : null,
            isLoading : true,
            albums : []
        };
    }

    componentDidMount () {
        axios.post( global.sg_url + 'api/get_albums'  )
            .then(response => {
                this.setState({ isLoading: false, albums: response.data });
            })
            .catch(error => {
                var errTxt = "Unknown error";
                switch (parseInt(error.response.status, 10)) {
                    case 401:
                        errTxt = "Not authenticated!";
                        break;
                    case 403:
                        errTxt = "Forbidden action!";
                        break;
                    case 404:
                        errTxt = "No data found!";
                        break;
                    default:
                        break;
                }
                this.setState({ isLoading: false, error: errTxt });
            });
    }

    render() {
        if(this.state.isLoading) {
            return <div className="">&nbsp;</div>
        } else {
            if (this.state.error && this.state.albums[0] === undefined) {
                return <div className="">
                    <h1>Error!</h1>
                    {this.state.error}
                </div>
            } else {
                return  <div className="albumGrid" id={'showContentSlow'}>
                            {
                                this.state.albums.map(album => (
                                    <div key={album.id} className="albumItem">
                                        {album.route_name !== null && album.route_name !== '' && album.is_active === 1 ?
                                            (<Link to={'/album/' + album.route_name}>
                                                <img src={global.sg_url + 'cover/albums/' + album.cover} border="0" width={300} height={300}
                                                     alt={global.stefano + ' - ' + album.album} title={album.album + ' (' + album.year + ')'}/>
                                            </Link>) :
                                            (<img src={global.sg_url + 'cover/albums/' + album.cover} border="0" width={300} height={300}
                                                  alt={global.stefano + ' - ' + album.album} title={album.album + ' (' + album.year + ')'}/>)
                                        }
                                    </div>
                                ))
                            }
                        </div>
            }
        }
    }
}
export default RecordsPage