import React, { Component , useContext } from 'react';
import ReactDOM from 'react-dom';

class Player extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            songs: props.songs,
            cover: '',
            title: '',
            trackId: ''
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({ songs: this.props.songs, trackId: this.props.trackId });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    shouldComponentUpdate(nextProps) {
        //console.log(nextProps.title);
        const differentTitle = this.props.title !== nextProps.title;
        const differentSongs = this.props.songs !== nextProps.songs;
        const differentTrackId = this.props.trackId !== nextProps.trackId;

        if(differentTrackId) {
            //console.log(nextProps.trackId);
            const element = ReactDOM.findDOMNode(this);
            const audios = element.querySelectorAll('audio');
            // Alle Audios erstmal pausieren und ausblenden
            [].forEach.call(audios, function(audio) {
                audio.pause();
                audio.parentNode.setAttribute('class', 'audioHide');
            });
            // Audio mit ID suchen und abspielen
            [].forEach.call(audios, function(audio) {
                if(nextProps.trackId !== '') { // , wenn expliziter Song
                    if (parseInt(audio.getAttribute('trackid')) === parseInt(nextProps.trackId)) {
                        audio.parentNode.setAttribute('class', 'audioShow');
                        audio.currentTime = 0;
                        audio.play();
                    }
                } else { // Wenn komplettes Album
                    if (audio.getAttribute('id') === 'player1') {
                        audio.parentNode.setAttribute('class', 'audioShow');
                        audio.currentTime = 0;
                        audio.play();
                    }
                }
            });
        }
        // Nur wenn sich die Songs geändert haben neu rendern!
        return differentSongs; // || differentTrackId differentTitle || differentSongs;
    }

    render() {
        var audioPlayer = [];
        //const jamendoUrl = 'https://mp3l.jamendo.com/?format=mp31&trackid='; // 1270256
        //https://api.jamendo.com/v3.0/artists/tracks/?client_id=666&id=352844&track_id=133049&audioformat=mp32&limit=1

        if( document.cookie.indexOf("SGcooacc=") === null ||
            document.cookie.indexOf("SGcooacc=") === undefined ||
            document.cookie.indexOf("SGcooacc=") < 0) {
            return (
                <div id="myPlayer" onClick={alert("Please accept cookies to listen to my music!")}>
                </div>
            )
        }

        if(this.props.songs !== undefined && this.props.songs !== null && this.props.songs.length > 0) {
            var songs = this.props.songs;
            var i = 1;
            var loopSong = false;
            if(songs.length === 1) {
                loopSong = true;
            }
            songs.map((song) => {
                if(song.jamendo_url !== undefined && song.jamendo_url !== null && song.jamendo_url !== '') {
                    var autoPlay = false;
                    var className = 'audioHide';
                    if(i === 1 && this.props.trackId === '') {
                        autoPlay = true;
                        className = 'audioShow';
                    }
                    if(this.props.trackId !== '' && this.props.trackId === song.id) {
                        autoPlay = true;
                        className = 'audioShow';
                    }
                    const thisPlayerId = i;
                    audioPlayer.push(
                        <div key={song.id} id={'playerContainer' + i} className={className}>
                            <p key={"stefanoSongTitle"}>{song.title}</p>
                            <audio id={'player' + i}
                                   autoPlay={autoPlay}
                                   loop={loopSong}
                                   trackid={song.id}
                                   controls="controls"
                                   crossOrigin={"anonymous"}
                                   key={"sgAudioControls"}
                                   onEnded={() => {
                                       if(!loopSong) {
                                           var nextPlayerId = (thisPlayerId+1);
                                           var nextAudioPlayer = document.getElementById('playerContainer' + nextPlayerId);
                                           if(nextAudioPlayer === undefined || nextAudioPlayer === null) {
                                               nextPlayerId = 1;
                                               nextAudioPlayer = document.getElementById('playerContainer1');
                                           }

                                           if(nextAudioPlayer !== undefined && nextAudioPlayer !== null) {
                                               var thisPlayer = document.getElementById('player' + thisPlayerId);
                                               // Diesen Audio-Player stoppen
                                               thisPlayer.pause();
                                               // Div zu diesem ausblenden
                                               var thisPlayerContainer = document.getElementById('playerContainer' + thisPlayerId);
                                               thisPlayerContainer.setAttribute('class', 'audioHide');
                                               // Div zu nächstem anzeigen
                                               nextAudioPlayer.setAttribute('class','audioShow');
                                               // Nächsten Player starten
                                               var nextPlayer = document.getElementById('player' + nextPlayerId);
                                               nextPlayer.load();
                                               nextPlayer.play();
                                           }
                                       }
                                   }}>
                                <source key={"sgDataSource"} data-track-number={song.track_no} title={song.title} src={song.jamendo_url} type="audio/mpeg"/>
                            </audio>
                        </div>
                    );
                    i++;
                }
            });
        } else {
            audioPlayer.push(<div key={"sgAudioPlayer"}><audio key={"sgAudioControls"} controls="controls"><source/></audio></div>);
        }

        return (
            <div id="myPlayer" key={"stefanoPlayer"}>
                {audioPlayer.map(player => (
                    player
                ))}
            </div>
        )
    }
}
export default Player;