import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import nl2br from 'react-nl2br'
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class MessagesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pwd: '',
            msgs: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if(event.target.value !== '') {
            this.setState({ pwd : event.target.value });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        if( this.state.pwd !== '') {
            var body = {'pwd': this.state.pwd};

            axios.post(global.sg_url + 'api/showmymsgs', body)
                .then(response => {
                    document.getElementById('msgFrmCtrl').setAttribute("style", "display: none;");
                    this.setState({ pwd: '', msgs: response.data });
                    this.render();
                })
                .catch(error => {
                    var errTxt = "Unknown error";
                    switch (parseInt(error.response.status, 10)) {
                        case 401:
                            errTxt = "Not authenticated!";
                            break;
                        case 403:
                            errTxt = "Forbidden action!";
                            break;
                        case 404:
                            errTxt = "No data found!";
                            break;
                        default:
                            break;
                    }
                    document.getElementById('submitMsg').innerHTML = errTxt;
                });
        }
    }

    render() {
        var messages = '';
        if(this.state.msgs !== null && this.state.msgs !== undefined && this.state.msgs.length > 0) {
            messages = this.state.msgs.map(msg => (
                <div key={msg.id}>
                    <b>{msg.subject}</b><br/>
                    <div>{msg.name} ({msg.mail})</div>
                    <div>{nl2br(msg.message)}</div>
                    <i>{msg.contact_time}</i>
                </div>
            ))
        }
        return(
          <div className="container maiPenRai">
              <div className="topTitle">Here u are...</div>
              <FormControl id="msgFrmCtrl">
                  <TextField label="Give it to me! 555" required={true} type="text" inputProps={ {maxLength: 12} } name="pwd" value={this.props.pwd} onChange={this.handleChange} />
                  <Button type="submit" onClick={this.handleSubmit}>&nbsp;</Button>
                  <div id="submitMsg">
                      &nbsp;&nbsp;
                  </div>
              </FormControl>
              <div id="myMessages">{messages}</div>
          </div>
        );
    }
}
export default MessagesPage;