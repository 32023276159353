import React, {Component, PureComponent} from 'react';
import axios from "axios";
import Cover from "../components/Cover";
import { Link } from 'react-router-dom'
import MetaData from "../components/MetaData";
import {func} from "prop-types";

class XmasPage extends PureComponent {
    constructor(props) {
        super(props);
        this.doorContent = this.doorContent.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    doorContent(day) {
        if(day !== null && day !== undefined && parseInt(day) > 0 && parseInt(day) < 25) {
            document.getElementById("door" + day).classList.toggle("open");

            var body = {'d': day, 'dx': 'mpr'};
            axios.post(global.sg_url + 'api/get_xmas_content', body)
                .then(response => {
                    var respData = response.data;
                    if(respData !== '' && respData.img !== null && respData.img !== undefined && respData.img !== '') {
                        document.getElementById("xmasGrid").style.display = 'none';

                        var dayContent = '<div class="xmasDayContent">';
                        dayContent += '<div class="xmasImg" style="margin: 15px auto; float: none;"><img id="cover" alt="' + respData.info + '" title="' + respData.info + '" src="/xmas/' + respData.img + '" /></div>';
                        var imgInfo = '';
                        if(respData.info !== null && respData.info !== undefined && respData.info !== '') {
                            dayContent += '<div class="xmaslInfo">' + respData.info + '</div>';
                            imgInfo = respData.info;
                        }

                        if(respData.song !== null && respData.song !== undefined && respData.song !== '') {
                            var title = respData.song;
                            if(respData.title !== null && respData.title !== undefined && respData.title !== '') {
                                title = respData.title;
                            }
                            dayContent += '<div>' + title + '</div>';
                            dayContent += '<audio controls><source src="/xmas/' + respData.song + '" type="audio/ogg"></audio>';
                        }

                        if( respData.album !== null && respData.album !== undefined && respData.album !== '' &&
                            respData.url !== null && respData.url !== undefined && respData.url !== '') {
                            dayContent += '<p style="margin: 0; padding: 0;"><a href="album/' + respData.url + '" target="_blank">Album: ' + respData.album + '</a></p>';
                        }
                        dayContent += '</div>';

                        document.getElementById("xmasContent").innerHTML = dayContent;
                        document.getElementById("xmasContent").style.display = 'block';
                        document.getElementById("backBtn").style.display = 'inline';
                        document.getElementById("backBtnBtm").style.display = 'inline';
                    } else {
                        document.getElementById("door" + day).classList.remove("open");
                    }
                })
                .catch(error => {
                    document.getElementById("door" + day).classList.remove("open");
                    // Nothing to do..
                });
        }
    }

    goBack() {
        document.getElementById("xmasContent").innerHTML = '';
        document.getElementById("xmasContent").style.display = 'none';
        document.getElementById("backBtn").style.display = 'none';
        document.getElementById("backBtnBtm").style.display = 'none';
        document.getElementById("xmasGrid").style.display = 'flex';
        document.getElementsByClassName("open")[0].classList.remove("open");
    }

    render() {
        document.body.style.backgroundColor = '#b20000';

        var toDate = new Date();
        var today = parseInt(toDate.getDate());
        var month = parseInt(toDate.getMonth()) + 1;
        var doors = [];
        for(var i = 1; i <= 24; i++){
            var doorClass = 'theDoor';
            if(i <= today && month === 12) {
                doorClass += ' active';
                if(i == today) {
                    doorClass += ' today';
                }
                var prevDoor = {
                        backgroundImage: "url(" + "/xmas/prev_" + i + ".jpg" + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'center',
                        backgroundRepeat: 'no-repeat'
                };
                doors.push(<div key={i} className={'xmasDoor'} style={prevDoor} onClick={this.doorContent.bind(null, i)}><div id={"door" + i} className={doorClass}>{i}</div></div>);
            } else {
                doors.push(<div key={i} className={'xmasDoor'}><div id={"door" + i} className={doorClass}>{i}</div></div>);
            }
        }
        doors.sort(() => Math.random() - 0.5)
        return (
            <div className="container xcont">
                <MetaData {...{ title: "Welcome to Stefano's Advent Calendar 2023!", keywords: global.stefano,
                    imgUrl: ''}}/>
                <div className="xmasBackground"></div>
                <div className="xmasBackgroundBlr"></div>
                <div className="containerXmas">
                    <div className="xmasGrid" id="xmasGrid" style={{display: 'flex'}}>
                        {doors}
                    </div>
                    <span className="xmasBack" onClick={this.goBack} id="backBtn" style={{display: 'none'}}>&lt; Back</span>
                    <div className="xmasContent" id="xmasContent" style={{display: 'none'}}></div>
                    <span className="xmasBack" onClick={this.goBack} id="backBtnBtm" style={{display: 'none'}}>&lt;&lt;</span>
                </div>
                <div className="angelLeft"></div>
                <div className="angelRight"></div>
            </div>
        )
    }
}
export default XmasPage