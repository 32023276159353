import React, { Component } from 'react'
import {Link} from "react-router-dom";

class BurgerMenu extends Component {
    constructor(props) {
        super(props)

        this.items = []
        for (let i=1; i<=5; i++) {
            this.items.push(i)
        }
    }

    render() {
        return (
            <div className="menu">
                <div className="menu-item">
                    <Link to="" className="menu-item" onClick={this.props.closeCallback}>
                        <div className="logoLargeBurger">&nbsp;</div>
                    </Link>
                </div>
                <div className="menu-item">
                    <Link to="/about" className="menu-item" onClick={this.props.closeCallback}>About</Link>
                </div>
                <div className="menu-item">
                    <Link to="/records" className="menu-item" onClick={this.props.closeCallback}>Records</Link>
                </div>
                <div className="menu-item">
                    <Link to="/impressions" className="menu-item" onClick={this.props.closeCallback}>Impressions</Link>
                </div>
                <div className="menu-item">
                    <Link to="/videos" className="menu-item" onClick={this.props.closeCallback}>Videos</Link>
                </div>
            </div>
        )
    }
}
// BurgerMenu.PropTypes = {
//     closeCallback: React.PropTypes.func.isRequired
// }
export default BurgerMenu;