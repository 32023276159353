import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
      var cookieNotice = '';
      if(document.cookie.indexOf("SGcooacc=") >= 0) {
      } else {
          cookieNotice = (
              <div id="cookieNotice">
                  <div>
                      This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies.
                      <p>If you want to listen to music by Stefano Giardinere on this website, you must accept cookies.</p>
                      <Link to="/privacy">Find out more in the Data Privacy Policy.</Link>
                  </div>
                  <button onClick={() => {
                      document.cookie = "SGcooacc=1; expires=Thu, 01 Jan 2555 00:00:00 UTC; path=/;";
                      document.getElementById('cookieNotice').setAttribute("style", "display:none;");
                      window.location.reload();
                  }}>Accept & Close</button>
              </div>
          )
      }
      return (
        <div>
            {cookieNotice}
            <footer>
                <span className={"footerOuterImg"}>
                    <a href="https://play.google.com/store/apps/details?id=com.sg_media_design.stefanogiardiniere" target="_blank" className={"footerImg"}><img src={'./android.png'} title="Stefano Giardiniere - Android App" alt="Stefano Giardiniere - Android App" /></a>
                    <a href="https://ko-fi.com/stefano78" target="_blank" className={"footerImg"}><img src={'./kofi.png'} title="Support me on Kofi" alt="Support Stefano Giardiniere now" /></a>
                </span>
                <Link to="/links">Links</Link># {/*<Link to="/album/bittersweet">2019</Link>*/} 2023 #<Link to="/contact">Contact</Link>
                <span className={"footerOuterImg"}>
                    <a href="https://www.facebook.com/pages/Stefano-Giardiniere/131213010309281" target="_blank" className={"footerImg"}><img src={'./fb.png'} title="Stefano Giardiniere on Facebook" alt="Stefano Giardiniere on Facebook" /></a>
                    <a href="https://soundcloud.com/stefano-giardiniere" target="_blank" className={"footerImg"}><img src={'./soundcloud.png'} title="Stefano Giardiniere on Soundcloud" alt="Stefano Giardiniere on Soundcloud" /></a>
                </span>
            </footer>
        </div>
      )
  }
}
export default Footer