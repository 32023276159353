import React, {Component, PureComponent} from 'react';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import nl2br from 'react-nl2br'
import { Link } from 'react-router-dom'

class ContactForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name:       '',
            mail:       '',
            subject:    '',
            message:    '',
            checkPrivacy:  false,
            respMsg: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        var fieldName = event.target.name;
        switch (fieldName) {
            case "mail":
                    this.setState({ mail : event.target.value });
                break;
            case "message":
                this.setState({ message : event.target.value });
                break;
            case "name":
                    this.setState({ name : event.target.value });
                break;
            case "checkPrivacy":
                this.setState({ checkPrivacy : true });
                break;
            case "subject":
                    this.setState({ subject : event.target.value });
                break;
            default:
                break;
        }
    }
    handleSubmit(event) {
        event.preventDefault();

        if( this.state.checkPrivacy &&
            this.state.name !== '' &&
            this.state.mail !== '' &&
            this.state.subject !== '' &&
            this.state.message !== '') {
            document.getElementById('submitMsg').innerHTML = "&nbsp;";
            var body = {'name': this.state.name, 'mail': this.state.mail, 'subject': this.state.subject, 'message' : this.state.message};

            axios.post(global.sg_url + 'api/send_message', body)
                .then(response => {
                    const respMsg = response.data;
                    localStorage.setItem('contactDone', "1");
                    this.setState({
                        name:           '',
                        mail:           '',
                        subject:        '',
                        message:        '',
                        checkPrivacy:   false,
                        respMsg:        respMsg
                    });
                })
                .catch(error => {
                    var errTxt = "Unknown error";
                    switch (parseInt(error.response.status, 10)) {
                        case 401:
                            errTxt = "Not authenticated!";
                            break;
                        case 403:
                            errTxt = "Forbidden action!";
                            break;
                        default:
                            break;
                    }
                    document.getElementById('submitMsg').innerHTML = errTxt;
                    document.getElementById('submitMsg').setAttribute("style", "color: #b20000;");
                });
        }
    }

    render () {
        var contactDone = localStorage.getItem('contactDone');
        if(contactDone === null && contactDone !== "1") {
            return(
                <form className="contactForm" onSubmit={this.handleSubmit} id={'showContentFaster'}>
                    <FormControl className="contactCard" id="contactFormControl">
                        <b>Send me a message:</b>
                        <TextField label="Name" required={true} type="text" inputProps={ {maxLength: 250} } name="name" value={this.props.name} onChange={this.handleChange} />
                        <TextField label="E-Mail" required={true} type="email" name="mail" inputProps={ {maxLength: 250} } value={this.props.mail} onChange={this.handleChange} />
                        <TextField label="Subject" required={true} type="text" name="subject" inputProps={ {maxLength: 250} } value={this.props.subject} onChange={this.handleChange} />
                        <TextField multiline={true} rows="5" required={true} label="Message" name="message" value={this.props.message} onChange={this.handleChange} />
                        <label htmlFor="checkPrivacy" className="checkLabel">
                            <input type="checkbox" className="contactCheck" value={this.props.checkPrivacy} onChange={this.handleChange} name="checkPrivacy" required={true} id="checkPrivacy" />
                            I accept the storage of my personal data for the purpose of processing my inquiry in accordance with the <Link to="/privacy" target="_blank">Privacy Policy</Link>.*
                        </label>
                        <Button type="submit">Submit</Button>
                        <div id="submitMsg">
                            &nbsp;&nbsp;
                        </div>
                    </FormControl>
                    <div className="privacyLink">
                        <Link to="/privacy">Data Privacy Policy</Link>
                    </div>
                </form>
            )
        } else {
            return(
                <form className="contactForm">
                    <FormControl className="contactCard" id="contactFormControlSuccess">
                        <b>Thank you for your message!</b>
                        <p>&nbsp;</p>
                        <div id="submitMsgSuccess">
                            {nl2br(this.state.respMsg)}
                        </div>
                    </FormControl>
                    <div className="privacyLink">
                        <Link to="/privacy">Data Privacy Policy</Link>
                    </div>
                </form>
            )
        }

    }
}
export default ContactForm;