import React, { Component } from 'react'
import { withRouter , BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { browserHistory } from 'react-router';
import {AppProvider} from "./AppContext";
import './global'

import Header from './components/Header'
import Footer from './components/Footer'
// import Player from "./components/Player";
import HomePage from './pages/HomePage'
import LoadPage from './pages/LoadPage' //LoadPage
import AlbumPage from './pages/AlbumPage'
import MessagesPage from './pages/MessagesPage'
import './App.css'
import XmasPage from "./pages/XmasPage";
import StartPage from "./pages/StartPage";

class App extends Component {
    // componentDidMount () {
    //     this.props.history.listen(() => {
    //         if (window.swUpdateReady) {
    //             window.swUpdateReady = false;
    //             window.stop();
    //             window.location.reload();
    //         }
    //     });
    // }

    render () {
        if(localStorage.getItem('contactDone') !== null && localStorage.getItem('contactDone') === "1") {
            localStorage.removeItem('contactDone');
        }
        return (
            <Router>
                <AppProvider>
                    <Header />
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/home" component={HomePage} />
                        <Route path="/about" component={LoadPage} />
                        <Route path="/records" component={LoadPage} />
                        <Route path="/impressions" component={LoadPage} />
                        <Route path="/privacy" component={LoadPage} />
                        <Route path="/videos" component={LoadPage} />
                        <Route path="/links" component={LoadPage} />
                        <Route path="/contact" component={LoadPage} />
                        <Route path="/album/:id" component={AlbumPage} />
                        <Route path="/me/1/a" component={MessagesPage} />
                        <Route path="/xmas" component={XmasPage} />
                    </Switch>
                    {/*<Player/>*//*XmasPage*/}
                    <Footer />
                </AppProvider>
            </Router>
        )
    }
}
export default App